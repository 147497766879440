import * as React from "react";

import { logout } from "../../../actions/auth";
import { ReduxActions, ReduxState } from "../../../store";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { KeycloakInstance } from "keycloak-js";

import { Divider, Drawer, Hidden, List, MenuItem, Typography, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "./responsive-side-menu.styles";
import { useHistory } from "react-router-dom";
import EtraLogo from "../../../resources/svg/EtraLogo.svg";
import strings from "../../../localization/strings";
import { WarehouseRESTEntity } from "../../../generated/client";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  keycloak?: KeycloakInstance;
  logout: typeof logout;
  open: boolean;
  toggleSideMenu: () => void;
  selectedWarehouse?: WarehouseRESTEntity;
}

/**
 * Top bar component
 * 
 * @param props component props
 */
const ResponsiveSideMenu: React.FC<Props> = props => {
  const {
    classes,
    open,
    selectedWarehouse,
    toggleSideMenu
  } = props;

  const history = useHistory();

  /**
   * Renders drawer content
   */
  const renderDrawerContent = () => {

    const selectedWarehouseIncator = selectedWarehouse ?
      strings.formatString(strings.currentWarehouseText, selectedWarehouse.name) :
      strings.selectWarehouse;

    return (
      <div className={ classes.drawerContent }>
        <div className={ classes.logoArea }>
          <img
            alt="Etra logo"
            src={ EtraLogo }
            className={ classes.logo }
          />
          <Typography className={ classes.logoText }>
            { strings.collectPoint }
          </Typography>
        </div>
        <List className={ classes.menu }>
          <Divider variant="middle" style={{ backgroundColor: "#fff" }} />
          <MenuItem
            className={ classes.menuItem }
            onClick={ () => history.push("/orders") }
          >
            { strings.orders }
          </MenuItem>
          <Divider variant="middle" style={{ backgroundColor: "#fff" }} />
          <MenuItem
            className={ classes.menuItem }
            onClick={ () => history.push("/warehouses") }
          >
            { strings.warehouses }
          </MenuItem>
          <Divider variant="middle" style={{ backgroundColor: "#fff" }} />
          <MenuItem
            className={ classes.menuItem }
            onClick={ () => history.push("/selectWarehouse") }
          >
            { selectedWarehouseIncator }
          </MenuItem>
          <Divider variant="middle" style={{ backgroundColor: "#fff", marginTop: "auto" }} />
          <MenuItem
            className={ classes.menuItem }
            onClick={ () => doLogout(props) }
          >
            { strings.logout }
          </MenuItem>
        </List>
      </div>
    );
  }

  /**
   * Component render
   */
  return (
    <div className={ classes.root }>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={ open }
          onClose={ toggleSideMenu }
          ModalProps={{
            keepMounted: true
          }}
        >
          { renderDrawerContent() }
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
        >
          { renderDrawerContent() }
        </Drawer>
      </Hidden>
    </div>
  );
}

/**
 * Executes logout
 * 
 * @param props component props
 */
const doLogout = (props: Props) => {
  props.logout();
  props.keycloak?.logout();
}

/**
 * Redux mapper for mapping store state to component props
 * 
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  keycloak: state.auth.keycloak,
  selectedWarehouse: state.warehouses.selectedWarehouse
});

/**
 * Redux mapper for mapping component dispatches 
 * 
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
  logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResponsiveSideMenu));

import * as React from "react";

import { ReduxActions, ReduxState } from "../../../store";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import { Button, Card, Typography, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "./home-screen.styles";
import strings from "../../../localization/strings";
import { History } from "history";
import AppLayout from "../../layouts/app-layout";
import { WarehouseRESTEntity } from "../../../generated/client";
import { AccessToken } from "../../../types";
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  history: History<History.LocationState>;
  accessToken?: AccessToken;
  selectedWarehouse?: WarehouseRESTEntity;
}

/**
 * Interface describing component state
 */
interface State {

}

/**
 * Component for home screen
 */
class HomeScreen extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props props 
   */
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Component render method
   */
  render() {
    const { classes } = this.props;

    return (
      <AppLayout>
        <div className={ classes.container }>
          { this.renderSelectedWarehouseCard() }
        </div>
        { this.renderCreateNewOrderButton() }
      </AppLayout>
    );
  }

  /**
   * Renders selected warehouse card
   */
  private renderSelectedWarehouseCard = () => {
    const { classes, history, selectedWarehouse } = this.props;

    return (
      <Card
        className={ classes.warehouseCard }
        onClick={ () => history.push("/selectWarehouse") }
      >
        <Typography
          color="textSecondary"
          variant="h5"
        >
          { selectedWarehouse ?
            selectedWarehouse.name :
            strings.noSelectedWarehouse
          }
        </Typography>
        <SwapHorizIcon className={ classes.swapIcon } />
      </Card>
    );
  }

  /**
   * Renders create new order button
   */
  private renderCreateNewOrderButton = () => {
    const { classes, history } = this.props;

    return (
      <Button
        variant="contained"
        color="primary"
        className={ classes.bigBlueButton }
        onClick={ () => history.push("/orders/new") }
      >
        { strings.addNewOrder }
      </Button>
    );
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  selectedWarehouse: state.warehouses.selectedWarehouse
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({ });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HomeScreen));

import { Button, CircularProgress, OutlinedTextFieldProps, TextField, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { styles } from "./create-warehouse-screen.styles";
import { History } from "history";
import strings from "../../../localization/strings";
import AppLayout from "../../layouts/app-layout";
import Api from "../../../api/api";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as actions from "../../../actions/warehouses";
import { AccessToken } from "../../../types";
import { Redirect } from "react-router-dom";
import { ReduxActions, ReduxState } from "../../../store";
import Alert from "@material-ui/lab/Alert";
import { WarehouseRESTEntity } from "../../../generated/client";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  history: History<History.LocationState>;
  accessToken?: AccessToken;
  warehouses: WarehouseRESTEntity[];
  setWarehouses: (warehouses: WarehouseRESTEntity[]) => void;
}

/**
 * Interface describing component state
 */
interface State {
  name: string;
  shopId: string;
  lockId: string;
  redirect: boolean;
  pristine: boolean,
  failure: boolean,
  success: boolean,
  loading: boolean,
}

/**
 * Component for new warehouse screen
 */
class CreateWarehouseScreen extends React.Component<Props, State> {
  /**
   * Component constructor
   * 
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      shopId: "",
      lockId: "",
      redirect: false,
      pristine: true,
      failure: false,
      success: false,
      loading: false
    };
  }

  /**
   * Component did mount life cycle method
   */
  componentDidMount = () => {
    this.setState({
      loading: false,
      pristine: true,
      failure: false,
      success: false
    });
  }

  /**
   * Component render method
   */
  render() {
    const { loading, redirect } = this.state;

    if (redirect) {
      return (
        <Redirect to="/warehouses" push={ true }/>
      );
    }

    const { classes } = this.props;


    return (
      <AppLayout>
        { loading ?
          <CircularProgress className={ classes.loader } /> :
          this.renderCreateWarehouseForm()
        }
      </AppLayout>
    );
  }

  /**
   * Renders create warehouse form
   */
  private renderCreateWarehouseForm = () => {
    const { classes, history } = this.props;
    const textFieldProps: OutlinedTextFieldProps = {
      size: "medium",
      fullWidth: true,
      required: true,
      className: classes.formField,
      variant: "outlined",
      InputLabelProps: { variant: "outlined" }
    };

    const { pristine, name, shopId, lockId, success, failure } = this.state;

    return (
      <div className={ classes.container }>
        <Typography className={ classes.primaryHeader } variant="h3">
          { strings.createWarehouseScreenHeader }
        </Typography>
        <div className={ classes.formContainer }>
          <TextField
            helperText={ !pristine && !name ? strings.requiredFieldError : "" }
            error={ !pristine && !name }
            value={ name || "" }
            onChange={ this.onNameChange }
            label={ strings.warehouseName }
            { ...textFieldProps }
          />
          <TextField
            helperText={ !pristine && !shopId ? strings.requiredFieldError : "" }
            error={ !pristine && !shopId }
            value={ shopId || "" }
            onChange={ this.onShopIdChange }
            label={ strings.warehouseShopId }
            { ...textFieldProps }
          />
          <TextField
            helperText={ !pristine && !lockId ? strings.requiredFieldError : "" }
            error={ !pristine && !lockId }
            value={ lockId || ""}
            onChange={ this.onLockIdChange }
            label={ strings.lockId }
            { ...textFieldProps }
          />
        </div>
        <div className={ classes.actionButtonContainer }>
          <Button
            onClick={ this.createWarehouse }
            className={ classes.actionButton }
            variant="contained"
            color="primary"
          >
            { strings.createWarehouse }
          </Button>
          <Button
            onClick={ () => history.goBack() }
            className={ classes.actionButton }
            variant="outlined"
          >
            { strings.cancelButtonText }
          </Button>
        </div>
        { success && 
          <Alert severity="success">
            { strings.createWarehouseSuccessAlert }
          </Alert>
        }
        { failure &&
          <Alert severity="error">
            { strings.createWarehouseFailureAlert }
          </Alert> 
        }
      </div>
    );
  }

  /**
   * Changes the name
   * 
   * @param event onChange-event
   */
  private onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: event.target.value });
  }

  /**
   * Changes the shop id
   * 
   * @param event onChange-event
   */
  private onShopIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ shopId: event.target.value });
  }

  /**
   * Changes the lock id
   * 
   * @param event onChange-event
   */
  private onLockIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ lockId: event.target.value });
  }

  /**
   * Sends a request to create a new warehouse and redirects to the list of warehouses
   */
  private createWarehouse = async () => {
    const {accessToken, setWarehouses, warehouses} = this.props;
    if (!accessToken) {
      return;
    }
    this.setState({ pristine: false });
    const { name, shopId, lockId } = this.state;
    if (!name || !shopId || !lockId) {
      return;
    }

    this.setState({loading: true});
    let didSucceed = true;
    try {
      const warehousesApi = Api.getWarehousesApi(accessToken);
      const created = await warehousesApi.warehouseControllerCreate({ warehouseRESTEntity: { name, shopId, lockId } });
      setWarehouses(warehouses.concat([created]));
      this.setState({ success: true, loading: false, pristine: true });
    } catch(e) {
      didSucceed = false;
      this.setState({ failure: true, loading: false });
    }

    setTimeout(() => {
      this.setState({
        failure: false,
        success: false,
        redirect: didSucceed
      });
    }, 5000);
  }
}

/**
 * Redux mapper for mapping store state to component props
 * 
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  warehouses: state.warehouses.warehouses
})

/**
 * Redux mapper for mapping component dispatches 
 * 
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
  setWarehouses: (warehouses: WarehouseRESTEntity[]) => dispatch(actions.setWarehouses(warehouses))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateWarehouseScreen));

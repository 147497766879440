import { createStyles } from "@material-ui/core";

export const styles = createStyles({

  root: {
  },

  menuButton: {
    color: "white"
  }

});

import { createStyles } from "@material-ui/core";
import theme from "../../../theme/theme";

export const styles = createStyles({

  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },

  warehouseCard: {
    width: "90%",
    maxWidth: 500,
    display: "flex",
    justifyContent: "space-between",
    AlignItems: "center",
    borderRadius: 2,
    marginTop: 20,
    padding: 20,
    boxShadow: "0 1px 1px 0 rgba(0, 0, 0, .25)",
    cursor: "pointer"
  },

  swapIcon: {
    color: theme.palette.text.secondary,
    fontSize: 30
  },

  bigBlueButton: {
    borderRadius: "50%",
    position: "absolute",
    top: "calc(50% - 150px)",
    left: "calc(50% - 150px)",
    width: 300,
    height: 300,
    fontSize: 40,
    boxShadow: "0 3px 6px 2px rgba(0, 0, 0, .5)",
    border: `1px solid ${theme.palette.grey[100]}`
  }

});
import { Button, Card, CardHeader, List, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { styles } from "./select-warehouse-screen.styles";
import { History } from "history";
import { connect } from "react-redux";
import { AccessToken } from "../../../types";
import { WarehouseRESTEntity } from "../../../generated/client";
import Api from "../../../api/api";
import { Dispatch } from "redux";
import * as actions from "../../../actions/warehouses";
import AppLayout from "../../layouts/app-layout";
import { Redirect } from "react-router-dom";
import strings from "../../../localization/strings";
import { ReduxActions, ReduxState } from "../../../store";



/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  history: History;
  accessToken?: AccessToken;
  warehouses: WarehouseRESTEntity[];
  selectWarehouse: (warehouse: WarehouseRESTEntity) => void;
  setWarehouses: (warehouses: WarehouseRESTEntity[]) => void;
}

/**
 * Interface describing component state
 */
interface State {
  selectedWarehouse?: WarehouseRESTEntity;
  redirect: boolean;
}

/**
 * Component for select warehouse screen
 */
class SelectWarehouseScreen extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  /**
   * Component did mount life cycle method
   */
  async componentDidMount() {
    const { accessToken, warehouses, setWarehouses } = this.props;
    if (!accessToken) {
      return;
    }

    if (warehouses.length < 1) {
      const warehousesApi = Api.getWarehousesApi(accessToken);
      setWarehouses(await warehousesApi.warehouseControllerList({}));
    }
  }

  /**
   * Component render method
   */
  render() {
    if (this.state.redirect) {
      return (
        <Redirect push={ true } to="/" />
      );
    }

    const { classes, warehouses } = this.props;

    const warehouseItems = warehouses.map(warehouse => {
      const listItemClass = this.resolveListItemClass(warehouse);

      return (
        <Card
          key={ warehouse.id }
          onClick={ () => this.selectWarehouse(warehouse) }
          className={ listItemClass }
        >
          <CardHeader
            title={ warehouse.name }
            titleTypographyProps={{
              className: classes.listItemTextColor
            }}
            subheader={ warehouse.shopId }
          />
        </Card>
      );
    })

    const disabled = !this.state.selectedWarehouse;
    return (
      <AppLayout>
        <List className={ classes.list }>
          { warehouseItems }
        </List>
        <div className={ classes.buttonArea }>
          <Button
            fullWidth
            onClick={ this.submitWarehouseSelection }
            classes={{
              contained: classes.button,
              disabled: classes.disabled
            }}
            disabled={ disabled }
            variant="contained"
            color="primary"
          >
            { strings.selectWarehouse }
          </Button>
        </div>
      </AppLayout>
    );
  }

  /**
   * Resolves the class of a list item
   * 
   * @param warehouse the warehouse of a list item
   */
  private resolveListItemClass = (warehouse: WarehouseRESTEntity): string => {
    const { classes } = this.props;
    if (this.state.selectedWarehouse && this.state.selectedWarehouse.id === warehouse.id) {
      return classes.selectedListItem;
    }

    return classes.listItem;
  }

  /**
   * Selects a warehouse
   * 
   * @param warehouse warehouse to select
   */
  private selectWarehouse = (warehouse: WarehouseRESTEntity) => {
    this.setState({ selectedWarehouse: warehouse });
  }

  /**
   * Submits the warehouse selection
   */
  private submitWarehouseSelection = () => {
    if (!this.state.selectedWarehouse) {
      return;
    }

    this.props.selectWarehouse(this.state.selectedWarehouse);
    this.setState({ redirect: true });
  }
}

/**
 * Redux mapper for mapping store state to component props
 * 
 * @param state store state
 */
export function mapStateToProps(state: ReduxState) {
  return {
    accessToken: state.auth.accessToken,
    warehouses: state.warehouses.warehouses
  };
}

/**
 * Redux mapper for mapping component dispatches 
 * 
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<ReduxActions>) {
  return {
    selectWarehouse: (warehouse: WarehouseRESTEntity) => dispatch(actions.setSelectedWarehouse(warehouse)),
    setWarehouses: (warehouses: WarehouseRESTEntity[]) => dispatch(actions.setWarehouses(warehouses))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SelectWarehouseScreen));

import { Button, CircularProgress, OutlinedTextFieldProps, TextField, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { ReduxActions, ReduxState } from "../../../store";
import { styles } from "./modify-warehouse-screen.styles";
import { History } from "history";
import { Redirect } from "react-router-dom";
import strings from "../../../localization/strings";
import AppLayout from "../../layouts/app-layout";
import Api from "../../../api/api";
import { AccessToken } from "../../../types";
import Alert from "@material-ui/lab/Alert";
import { WarehouseRESTEntity } from "../../../generated/client";
import * as actions from "../../../actions/warehouses"

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  history: History<History.LocationState>;
  warehouseId: number;
  accessToken?: AccessToken;
  warehouses: WarehouseRESTEntity[];
  setWarehouses: (warehouses: WarehouseRESTEntity[]) => void;
}

/**
 * Interface describing component state
 */
interface State {
  name: string;
  shopId: string;
  lockId: string;
  redirect: boolean;
  pristine: boolean,
  failure: boolean,
  success: boolean,
  loading: boolean,
}

/**
 * Component for modify warehouse screen
 */
class ModifyWarehouseScreen extends React.Component<Props, State> {
  /**
   * Component constructor
   * 
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      shopId: "",
      lockId: "",
      redirect: false,
      pristine: true,
      failure: false,
      success: false,
      loading: false
    };
  }

  async componentDidMount() {
    if (!this.props.accessToken) {
      return;
    }
    this.setState({ loading: true, pristine: true, failure: false, success: false });
    const warehousesApi = Api.getWarehousesApi(this.props.accessToken);
    const warehouse = await warehousesApi.warehouseControllerFind({ id: this.props.warehouseId });
    const { name, shopId, lockId } = warehouse;
    this.setState({ name, shopId, lockId, loading: false });
  }

  /**
   * Component render method
   */
  render() {
    const { loading, redirect } = this.state;

    if (redirect) {
      return (
        <Redirect to="/warehouses" push={ true }/>
      );
    }

    const { classes } = this.props;


    return (
      <AppLayout>
        { loading ?
          <CircularProgress className={ classes.loader } /> :
          this.renderWarehouseModifyForm()
        }
      </AppLayout>
    );
  }

  private renderWarehouseModifyForm = () => {
    const { classes, history } = this.props;
    const { pristine, name, shopId, lockId, success, failure } = this.state;

    const textFieldProps: OutlinedTextFieldProps = {
      size: "medium",
      fullWidth: true,
      required: true,
      className: classes.formField,
      variant: "outlined",
      InputLabelProps: { variant: "outlined" }
    };

    return (
      <div className={ classes.container }>
        <Typography className={ classes.primaryHeader } variant="h3">
          { strings.modifyWarehouseScreenHeader }
        </Typography>
        <div className={ classes.formContainer }>
          <TextField
            required
            label={ strings.warehouseName }
            helperText={ !pristine && !name ? strings.requiredFieldError : "" }
            error={ !pristine && !name }
            value={ name || "" }
            onChange={ this.onNameChange }
            { ...textFieldProps }
          />
          <TextField
            required
            label={ strings.warehouseShopId }
            helperText={ !pristine && !shopId ? strings.requiredFieldError : "" }
            error={ !pristine && !shopId }
            value={ shopId || "" }
            onChange={ this.onShopIdChange }
            { ...textFieldProps }
          />
          <TextField
            required
            label={ strings.lockId }
            helperText={ !pristine && !lockId ? strings.requiredFieldError : "" }
            error={ !pristine && !lockId }
            value={ lockId || "" }
            onChange={ this.onLockIdChange }
            { ...textFieldProps }
          />
        </div>
        <div className={ classes.actionButtonContainer }>
          <Button
            onClick={ this.updateWarehouse }
            className={ classes.actionButton }
            variant="contained"
            color="primary"
          >
            { strings.updateWarehouse }
          </Button>
          <Button
            onClick={ () => history.goBack() }
            className={ classes.actionButton }
            variant="outlined"
          >
            { strings.cancelButtonText }
          </Button>
        </div>
        { success && 
          <Alert severity="success">
            { strings.modifyWarehouseSuccessAlert }
          </Alert>
        }
        { failure &&
          <Alert severity="error">
            { strings.modifyWarehouseFailureAlert }
          </Alert> 
        }
      </div>
    );
  }

  /**
   * Changes the name
   * 
   * @param event onChange-event
   */
  private onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: event.target.value });
  }

  /**
   * Changes the shop id
   * 
   * @param event onChange-event
   */
  private onShopIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ shopId: event.target.value });
  }

  /**
   * Changes the lock id
   * 
   * @param event onChange-event
   */
  private onLockIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ lockId: event.target.value });
  }

  /**
   * Sends a request to update the warehouse and redirects to the list of warehouses
   */
  private updateWarehouse = async () => {
    const {accessToken, setWarehouses, warehouses} = this.props;
    if (!accessToken) {
      return;
    }
    this.setState({ pristine: false });
    const { name, shopId, lockId } = this.state;
    if (!name || !shopId || !lockId) {
      return;
    }
    this.setState({loading: true});
    let didSucceed = true;
    try {
      const warehousesApi = Api.getWarehousesApi(accessToken);
      const updated = await warehousesApi.warehouseControllerUpdate({ warehouseRESTEntity: { name, shopId, lockId }, id: this.props.warehouseId });
      setWarehouses(warehouses.map(w => w.id === updated.id ? updated : w));
      this.setState({ success: true, loading: false, pristine: true });
    } catch(e) {
      didSucceed = false;
      this.setState({ failure: true, loading: false });
    }

    setTimeout(() => {
      this.setState({
        failure: false,
        success: false,
        redirect: didSucceed
      });
    }, 5000);
  }
}

/**
 * Redux mapper for mapping store state to component props
 * 
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  warehouses: state.warehouses.warehouses
});

/**
 * Redux mapper for mapping component dispatches 
 * 
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
  setWarehouses: (warehouses: WarehouseRESTEntity[]) => dispatch(actions.setWarehouses(warehouses))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModifyWarehouseScreen));

import * as React from "react";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ReduxActions, ReduxState } from "../../store";
import { setWarehouses } from "../../actions/warehouses";
import Api from "../../api/api";
import { WarehouseRESTEntity } from "../../generated/client";
import { AccessToken } from "../../types";
import { KeycloakInstance } from "keycloak-js";


/**
 * Component props
 */
interface Props {
  keycloak: KeycloakInstance;
  accessToken: AccessToken;
  children?: React.ReactNode;
  warehouses: WarehouseRESTEntity[];
  setWarehouses: typeof setWarehouses;
};

/**
 * Component state
 */
interface State {
  error?: Error;
}

/**
 * Component for fetching initial data
 */
class StoreInitializer extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = { };
  }

  /**
   * Component did mount life cycle method
   */
  public componentDidMount = () => {
    this.fetchData();
  }

  /**
   * Component did update life cycle method
   *
   * @param prevProps previous component props
   */
  public componentDidUpdate = (prevProps: Props) => {
    if (!prevProps.accessToken && this.props.accessToken) {
      this.fetchData();
    }
  }

  /**
   * Component render method
   */
  public render = () => {
    const { warehouses, children } = this.props;

    return (
      warehouses ?
        children :
        null
    );
  }

  /**
   * Fetch app data
   */
  private fetchData = async () => {
    try {
      const { accessToken, setWarehouses } = this.props;
      if (!accessToken) {
        return;
      }
      const warehousesApi = Api.getWarehousesApi(accessToken);

      const warehouses = await warehousesApi.warehouseControllerList({ });

      setWarehouses(warehouses);
    } catch (error) {
      this.setState({ error });
    }
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 * @returns state from props
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken as AccessToken,
  keycloak: state.auth.keycloak as KeycloakInstance,
  warehouses: state.warehouses.warehouses
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
  setWarehouses: (warehouses: WarehouseRESTEntity[]) => dispatch(setWarehouses(warehouses))
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreInitializer);

import { createStyles } from "@material-ui/core";
import theme from "../../../theme/theme";

export const styles = createStyles({

  root: {
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },

  content: {
    height: "100%",
    flexGrow: 1,
    position: "relative",
    overflowY: "auto"
  }

});
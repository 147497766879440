import * as React from "react";
import { withStyles, WithStyles } from "@material-ui/core";
import { styles } from "./app-layout.styles";
import MobileTopBar, { OptionalProps as MobileTopBarProps } from "../../generic/mobile-top-bar";
import ResponsiveSideMenu from "../../generic/responsive-side-menu";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  mobileTopBarProps?: MobileTopBarProps;
}

/**
 * App layout component
 * 
 * @param props component props
 */
const AppLayout: React.FC<Props> = ({
  classes,
  children,
  mobileTopBarProps
}) => {

  const [ sideMenuOpen, toggleSideMenu ] = React.useState(false);
  const toggle = () => toggleSideMenu(!sideMenuOpen);

  return (
    <div className={ classes.root }>
      <MobileTopBar
        toggleSideMenu={ toggle }
        { ...mobileTopBarProps }
      />
      <ResponsiveSideMenu
        open={ sideMenuOpen }
        toggleSideMenu={ toggle }
      />
      <div className={ classes.content }>
        { children }
      </div>
    </div>
  );
}

export default withStyles(styles)(AppLayout);
import * as React from "react";
import { AppBar, Hidden, IconButton, Toolbar, Typography, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "./mobile-top-bar.styles";
import MenuIcon from '@material-ui/icons/Menu';

/**
 * Optional props
 */
export interface OptionalProps {
  title?: string;
}

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  toggleSideMenu: () => void;
}

/**
 * Top bar component
 * 
 * @param props component props
 */
const MobileTopBar: React.FC<Props & OptionalProps> = ({
  classes,
  title,
  toggleSideMenu
}) => {
  return (
    <Hidden mdUp implementation="css">
      <div className={ classes.root }>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className={ classes.menuButton }
              onClick={ toggleSideMenu }
            >
              <MenuIcon />
            </IconButton>
            { title &&
              <Typography variant="h3">
                { title }
              </Typography>
            }
          </Toolbar>
        </AppBar>
      </div>
    </Hidden>
  );
}

export default withStyles(styles)(MobileTopBar);
import * as React from "react";
import { Button, Card, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Hidden, IconButton, List, Tab, Tabs, Typography, WithStyles, withStyles } from "@material-ui/core";

import { ReduxActions, ReduxState } from "../../../store";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import { styles } from "./orders-screen.styles";
import { History } from "history";
import { AccessToken } from "../../../types";
import { OrderRESTEntity, WarehouseRESTEntity } from "../../../generated/client";
import Api from "../../../api/api";
import strings from "../../../localization/strings";
import AddIcon from '@material-ui/icons/Add';
import AppLayout from "../../layouts/app-layout";
import moment from "moment";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  history: History<History.LocationState>;
  accessToken?: AccessToken;
  selectedWarehouse?: WarehouseRESTEntity;
}

/**
 * Interface describing component state
 */
interface State {
  orders: OrderRESTEntity[];
  expiredOrders: OrderRESTEntity[];
  openTab: number
  expireOrderId?: number
}

/**
 * Component for orders screen
 */
class OrdersScreen extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      orders: [],
      expiredOrders: [],
      openTab: 0
    };
  }

  /**
   * Component did mount life cycle method
   */
  async componentDidMount() {
    this.updateOrderLists();
  }

  private updateOrderLists = async () => {
    const { selectedWarehouse, accessToken } = this.props;
    if (!accessToken || !selectedWarehouse) {
      return;
    }

    const ordersApi = Api.getOrdersApi(accessToken);
    const [orders, expiredOrders] = await Promise.all([
      ordersApi.orderControllerList({ warehouseId: selectedWarehouse.id!, expiresAfter: moment().format() }),
      ordersApi.orderControllerList({ warehouseId: selectedWarehouse.id!, expiresBefore: moment().format(), maxResults: 50 })
    ]);
    this.setState({ orders: orders, expiredOrders: expiredOrders });
  }

  private expireOrder = async (orderId: number) => {
    const { accessToken } = this.props;
    if (!accessToken) {
      return;
    }    

    try {
      const ordersApi = Api.getOrdersApi(accessToken);
      await ordersApi.orderControllerDelete({ id: orderId, permanent: false });
      this.updateOrderLists();
    } catch(e) {
      //TODO: handle error
    }
    this.setState({
      expireOrderId: undefined
    });
  }

  /**
   * Component render method
   */
  render() {
    const { classes, history, selectedWarehouse } = this.props;
    const { openTab } = this.state;

    const noSelectedWarehouseInfo = !selectedWarehouse ? (
      <Card
        className={ classes.infoCard }
        onClick={ () => history.push("/selectWarehouse") }
      >
        <CardHeader
          title={ strings.noSelectedWarehouse }
          className={ classes.infoCardHeader }
          subheader={ strings.selectWarehouseToSeeOrders }
          subheaderTypographyProps={{
            className: classes.textWhite
          }}
        />
      </Card>
    ) : undefined;

    const orders = this.state.orders.map(order => {
      return (
        <Card key={ order.id } className={ classes.listItem }>
          <CardHeader
            title={ strings.formatString(strings.orderTitle, order.orderNumber) }
            subheader={ strings.formatString(strings.orderPhoneNumber, order.phoneNumber, moment(order.expires).format("DD.MM.YYYY")) }
            action={
              <Button onClick={ () => this.setState({expireOrderId: order.id})} variant="outlined" color="secondary">{strings.expireButton}</Button>
            }
          />
        </Card>
      );
    });

    const expiredOrders = this.state.expiredOrders.map(order => {
      return (
        <Card key={ order.id } className={ classes.listItem }>
          <CardHeader
            title={ strings.formatString(strings.orderTitle, order.orderNumber) }
            subheader={ strings.formatString(strings.orderPhoneNumberExpired, order.phoneNumber) }
          />
        </Card>
      );
    });

    return (
      <AppLayout>
        <Hidden smDown>
          <div className={ classes.headerArea }>
            <Typography className={ classes.primaryHeader } variant="h3">
              { strings.orders }
            </Typography>
            <Button
              className={ classes.desktopButton }
              onClick={ () => history.push("/orders/new") }
            >
              { strings.addNewOrder }
            </Button>
          </div>
        </Hidden>
        <div>
          { noSelectedWarehouseInfo }
          <Tabs
            style={{ padding: "2em" }}
            value={ openTab }
            onChange={ (e, index) => this.setState({ openTab: index }) }
            aria-label="simple tabs example"
          >
            <Tab label={ strings.validOrdersTabTitle } />
            <Tab label={ strings.expiredOrdersTabTitle } />
          </Tabs>
        </div>
        <div hidden={openTab !== 0}>
          <List className={ classes.list }>
            { orders }
          </List>
        </div>
        <div hidden={openTab !== 1}>
          <List className={ classes.list }>
            { expiredOrders }
          </List>
        </div>
        <Hidden mdUp>
          <div className={ classes.buttonBackground }>
            <IconButton onClick={ () => history.push("/orders/new") } className={ classes.iconButton }>
              <AddIcon fontSize="inherit" htmlColor="#fff" />
            </IconButton>
          </div>
        </Hidden>
        <Dialog
          open={this.state.expireOrderId !== undefined}
          onClose={() => this.setState({ expireOrderId: undefined })}
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText>
              { strings.promptExpireOrder }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={ () => this.setState({ expireOrderId: undefined }) }
              color="primary"
            >
              { strings.cancelButtonText }
            </Button>
            <Button
              onClick={ () => this.expireOrder(this.state.expireOrderId!) }
              color="primary"
            >
              { strings.expireButton }
            </Button>
          </DialogActions>
        </Dialog>
      </AppLayout>
    );
  }

}

/**
 * Redux mapper for mapping store state to component props
 * 
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  selectedWarehouse: state.warehouses.selectedWarehouse
});

/**
 * Redux mapper for mapping component dispatches 
 * 
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({ });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrdersScreen));

import { createStyles } from "@material-ui/core";
import theme from "../../../theme/theme";

export const styles = createStyles({

  container: {
    height: "calc(100% - 56px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "2em",
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.up("md")]: {
      height: "100%"
    }
  },

  primaryHeader: {
    color: theme.palette.text.primary,

    [theme.breakpoints.up("md")]: {
      alignSelf: "flex-start",
      paddingLeft: "1em"
    }
  },

  formContainer: {
    width: "100%",
    padding: "2em"
  },

  formField: {
    marginTop: 20
  },

  formFieldInput: {
    color: theme.palette.text.secondary
  },

  actionButtonContainer: {
    marginTop: "auto",
    height: "auto",
    marginBottom: "2em",
    padding: "15px 10px",
    width: "calc(100% - 4em)",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      width: 500,
      marginTop: "0",
      marginLeft: "2em",
      alignSelf: "flex-start",
      flexDirection: "row"
    }
  },

  actionButton: {
    padding: 15,
    border: `1px solid ${theme.palette.grey[100]}`,
    width: "100%",
    height: 56,

    "&:not(:last-child)": {
      marginBottom: "2em"
    },

    [theme.breakpoints.up("md")]: {
      width: 200,
      marginTop: "0",
      marginRight: "2em"
    }
  },

  loader: {
    marginTop: "40%"
  }

});
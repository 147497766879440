import { createStyles } from "@material-ui/core";
import theme from "../../../theme/theme";

export const styles = createStyles({
  listItemTextColor: {
    color: "#000"
  },
  listItem: {
    marginBottom: 10
  },
  selectedListItem: {
    marginBottom: 10,
    borderColor: theme.palette.primary.main,
    borderWidth: 3,
    borderStyle: "solid"
  },
  list: {
    marginLeft: 5, 
    marginRight: 5, 
    marginBottom: 100 
  },
  buttonArea: {
    backgroundColor: "#fff", 
    position: "fixed", 
    bottom: 0, 
    width: "100%",
    padding: 10, 
    display: "flex", 
    justifyContent: "center",
    boxShadow: "0 1px 10px 0 rgba(0, 0, 0, 0.25)",
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 280px)",
      justifyContent: "flex-end"
    }
  },
  button: {
    padding: "15px 10px",
    border: `1px solid ${theme.palette.grey[100]}`,
    margin: 10,
    maxWidth: 500,
    "&$disabled": {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[500]
    },

    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },

  disabled: {
    backgroundColor: theme.palette.grey[200]
  }
});
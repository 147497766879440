import { createStyles } from "@material-ui/core";
import theme from "../../../theme/theme";

export const styles = createStyles({

  headerArea: {
    padding: "2em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },

  desktopButton: {
    padding: "10px 15px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },

  primaryHeader: {
    color: theme.palette.text.primary
  },

  listItem: {
    marginBottom: 10
  },

  infoCard: {
    marginTop: "2em",
    marginLeft: "2em",
    marginRight: "2em",
    cursor: "pointer",

    [theme.breakpoints.up("md")]: {
      marginTop: 0
    }
  },

  infoCardHeader: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  },

  textWhite: {
    color: theme.palette.common.white
  },

  list: {
    marginLeft: 5,
    marginRight: 5,
    marginBottom: 100
  },

  buttonBackground: {
    position: "fixed",
    right: 20,
    bottom: 20,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0 2px 4px 1px rgba(0, 0, 0, .5)",
    border: `1px solid ${theme.palette.grey[100]}`
  },

  iconButton: {
    fontSize: 50
  }

});
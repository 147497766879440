import { WarehousesAction } from '../actions/warehouses';
import { SET_SELECTED_WAREHOUSE, SET_WAREHOUSES } from '../constants/actionTypes';
import { Reducer } from "redux";
import { WarehouseRESTEntity } from '../generated/client';

/**
 * Warehouses state
 */
interface WarehousesState {
  warehouses: WarehouseRESTEntity[];
  selectedWarehouse?: WarehouseRESTEntity;
}

/**
 * Initial warehouses state
 */
const initialState: WarehousesState = {
  warehouses: [],
  selectedWarehouse: undefined
}

/**
 * Redux reducer for warehouses
 *
 * @param storeState store state of warehouses
 * @param action action of warehouses
 */
export const warehousesReducer: Reducer<WarehousesState, WarehousesAction> = (state = initialState, action): WarehousesState => {
  switch (action.type) {
    case SET_WAREHOUSES:
      return {
        ...state,
        warehouses: action.warehouses
      };
      case SET_SELECTED_WAREHOUSE:
        return {
          ...state,
          selectedWarehouse: action.warehouse
        };
    default:
      return state;
  }
}
import { Configuration, WarehousesApi, OrdersApi } from "../generated/client";
import { AccessToken } from "../types";

/**
 * Utility class for loading api with predefined configuration
 */
export default class Api {

  /**
   * Gets initialized warehouses api
   *
   * @param token access token
   */
  public static getWarehousesApi(accessToken: AccessToken) {
    return new WarehousesApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets initialized orders api
   *
   * @param token access token
   */
  public static getOrdersApi(accessToken: AccessToken) {
    return new OrdersApi(Api.getConfiguration(accessToken));
  }

  /**
   * Gets api configuration
   *
   * @param token access token
   */
  private static getConfiguration(accessToken: AccessToken) {
    return new Configuration({
      basePath: process.env.REACT_APP_API_BASE_PATH,
      headers: {
        "Authorization": `Bearer ${ accessToken.token }`
      }
    });
  }

}

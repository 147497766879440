import { combineReducers } from "redux";
import { authReducer } from "../reducers/auth";
import { localeReducer } from "../reducers/locale";
import { warehousesReducer } from "../reducers/warehouses";
import { ordersReducer } from "../reducers/orders";
import { AuthAction } from "../actions/auth";
import { LocaleAction } from "../actions/locale";
import { WarehousesAction } from "../actions/warehouses";
import { OrdersAction } from "../actions/orders";

/**
 * Root reducer that wraps all Redux reducers
 */
export const rootReducer = combineReducers({
    auth: authReducer,
    locale: localeReducer,
    warehouses: warehousesReducer,
    orders: ordersReducer
});

export type ReduxState = ReturnType<typeof rootReducer>;

export type ReduxActions = AuthAction | LocaleAction | WarehousesAction | OrdersAction;
import { OrdersAction } from '../actions/orders';
import { SET_ORDERS } from '../constants/actionTypes';
import { Reducer } from "redux";
import { OrderRESTEntity } from '../generated/client';

/**
 * Orders state
 */
interface OrdersState {
  orders: OrderRESTEntity[]
}

/**
 * Initial orders state
 */
const initialState: OrdersState = {
  orders: []
}

/**
 * Redux reducer for orders
 *
 * @param storeState store state of orders
 * @param action action of orders
 */
export const ordersReducer: Reducer<OrdersState, OrdersAction> = (state = initialState, action): OrdersState => {
  switch (action.type) {
    case SET_ORDERS:
      return {
        ...state,
        orders: action.orders
      };
    default:
      return state;
  }
}
import { Button, Card, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Hidden, IconButton, List, Menu, MenuItem, Typography, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { styles } from "./warehouses-screen.styles";
import { History } from "history";
import { connect } from "react-redux";
import { AccessToken } from "../../../types";
import { WarehouseRESTEntity } from "../../../generated/client";
import Api from "../../../api/api";
import { Dispatch } from "redux";
import { ReduxActions, ReduxState } from "../../../store";
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import AppLayout from "../../layouts/app-layout";
import { Redirect } from "react-router-dom";
import * as actions from "../../../actions/warehouses";
import theme from "../../../theme/theme";
import strings from "../../../localization/strings";


/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  history: History;
  accessToken?: AccessToken;
  warehouses: WarehouseRESTEntity[];
  setWarehouses: (warehouses: WarehouseRESTEntity[]) => void;
}

/**
 * Interface describing component state
 */
interface State {
  deleteDialogOpen: boolean;
  warehouseIdOpen: null | number;
  warehouseMenuAnchor: null | HTMLElement;
  redirect: {
    createWarehouse: boolean;
    modifyWarehouse: boolean;
    warehouseId?: number;
  }
}

/**
 * Component for warehouses screen
 */
class WarehousesScreen extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      deleteDialogOpen: false,
      warehouseIdOpen: null,
      warehouseMenuAnchor: null,
      redirect: {
        createWarehouse: false,
        modifyWarehouse: false,
        warehouseId: 0
      }
    };
  }

  /**
   * Component did mount life cycle method
   */
  async componentDidMount() {
    const { accessToken, warehouses, setWarehouses } = this.props;
    if (!accessToken) {
      return;
    }

    if (warehouses.length < 1) {
      const warehousesApi = Api.getWarehousesApi(accessToken);
      setWarehouses(await warehousesApi.warehouseControllerList({}));
    }
  }

  /**
   * Component render method
   */
  render() {
    const { createWarehouse, modifyWarehouse, warehouseId } = this.state.redirect;

    if (createWarehouse) {
      return (
        <Redirect push={ true } to="/warehouses/new"/>
      );
    }

    if (modifyWarehouse && warehouseId) {
      return (
        <Redirect push={ true } to={ `/warehouses/${warehouseId}` } />
      );
    }

    const { classes, warehouses } = this.props;

    const warehouseItems = warehouses.map(warehouse => {
      return (
        <Card key={ warehouse.id } className={ classes.listItem }>
          <CardHeader
            title={ warehouse.name }
            subheader={ warehouse.shopId }
            action={
              <IconButton onClick={ (e) => this.setState( { warehouseMenuAnchor: e.currentTarget, warehouseIdOpen: warehouse.id || null } ) }>
                <EditIcon htmlColor={ theme.palette.text.secondary } />
              </IconButton>
            }
          />
        </Card>
      );
    })

    const { warehouseMenuAnchor, warehouseIdOpen, deleteDialogOpen } = this.state;

    return (
      <AppLayout>
        <Hidden smDown>
          <div className={ classes.headerArea }>
            <Typography className={ classes.primaryHeader } variant="h3">
              { strings.warehouses }
            </Typography>
            <Button
              className={ classes.desktopButton }
              onClick={ this.redirectToCreateWarehouse }
            >
              { strings.createWarehouse }
            </Button>
          </div>
        </Hidden>
        <List className={ classes.list }>
          { warehouseItems }
        </List>
        <Hidden mdUp implementation="css">
          <div className={ classes.buttonBackground }>
            <IconButton onClick={ this.redirectToCreateWarehouse } className={ classes.iconButton }>
              <AddIcon fontSize="inherit" htmlColor="#fff" />
            </IconButton>
          </div>
        </Hidden>
        <Menu
          anchorEl={ warehouseMenuAnchor }
          keepMounted
          open={ Boolean(warehouseMenuAnchor) }
          onClose={this.handleMenuClose}
        >
          <MenuItem className={ classes.contextMenu } onClick={() => this.redirectToModifyWarehouse(warehouseIdOpen)}>{ strings.modifyButtonText }</MenuItem>
          <MenuItem className={ classes.contextMenu } onClick={() => this.setState({ deleteDialogOpen: true })}>{ strings.deleteButtonText }</MenuItem>
          <MenuItem className={ classes.contextMenu } onClick={this.handleMenuClose}>{ strings.cancelButtonText }</MenuItem>
        </Menu>
        <Dialog
          open={deleteDialogOpen}
          onClose={() => this.setState({ deleteDialogOpen: false })}
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText>
              { strings.promptDeleteWarehouse }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={ () => this.setState({ deleteDialogOpen: false }) }
              color="primary"
            >
              { strings.cancelButtonText }
            </Button>
            <Button
              onClick={ () => this.deleteWarehouse(warehouseIdOpen) }
              color="primary"
            >
              { strings.deleteButtonText }
            </Button>
          </DialogActions>
        </Dialog>
      </AppLayout>
    );
  }

  /**
   * Event handler for menu close
   */
  private handleMenuClose = () => {
    this.setState({
      warehouseMenuAnchor: null,
      warehouseIdOpen: null
    });
  }

  /**
   * Delete warehouse method
   *
   * @param warehouseId warehouse ID
   */
  private deleteWarehouse = async (warehouseId: number | null) => {
    const { warehouses, accessToken, setWarehouses } = this.props;
    if (!warehouseId || !accessToken) {
      return;
    }    

    try {
      const warehousesApi = Api.getWarehousesApi(accessToken);
      await warehousesApi.warehouseControllerDelete({id: warehouseId});
      setWarehouses(warehouses.filter(w => w.id !== warehouseId));
    } catch(e) {
      //TODO: handle error
    }
    this.setState({
      warehouseIdOpen: null,
      warehouseMenuAnchor: null,
      deleteDialogOpen: false
    });
  }

  /**
   * Redirects to the create warehouse screen
   */
  private redirectToCreateWarehouse = () => {
    const redirect = { ...this.state.redirect, createWarehouse: true };
    this.setState({ redirect });
  }

  /**
   * Redirects to the modify warehouse screen
   * 
   * @param warehouseId warehouse to modify
   */
  private redirectToModifyWarehouse = (warehouseId: number | null) => {
    if (!warehouseId) {
      return;
    }

    const redirect = { ...this.state.redirect, modifyWarehouse: true, warehouseId };
    this.setState({ redirect });
  }

}

/**
 * Redux mapper for mapping store state to component props
 * 
 * @param state store state
 */
export function mapStateToProps(state: ReduxState) {
  return {
    accessToken: state.auth.accessToken,
    warehouses: state.warehouses.warehouses
  };
}

/**
 * Redux mapper for mapping component dispatches 
 * 
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<ReduxActions>) {
  return {
    setWarehouses: (warehouses: WarehouseRESTEntity[]) => dispatch(actions.setWarehouses(warehouses))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WarehousesScreen));

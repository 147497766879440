/* tslint:disable */
/* eslint-disable */
/**
 * Etra clearance API
 * Etra clearance API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OrderRESTEntity,
    OrderRESTEntityFromJSON,
    OrderRESTEntityToJSON,
} from '../models';

export interface OrderControllerCreateRequest {
    orderRESTEntity: OrderRESTEntity;
}

export interface OrderControllerDeleteRequest {
    id: number;
    permanent: boolean;
}

export interface OrderControllerFindRequest {
    id: number;
}

export interface OrderControllerListRequest {
    warehouseId?: number;
    phoneNumber?: string;
    expiresAfter?: string;
    expiresBefore?: string;
    firstResult?: number;
    maxResults?: number;
}

export interface OrderControllerUpdateRequest {
    orderRESTEntity: OrderRESTEntity;
    id: number;
}

/**
 * 
 */
export class OrdersApi extends runtime.BaseAPI {

    /**
     */
    async orderControllerCreateRaw(requestParameters: OrderControllerCreateRequest): Promise<runtime.ApiResponse<OrderRESTEntity>> {
        if (requestParameters.orderRESTEntity === null || requestParameters.orderRESTEntity === undefined) {
            throw new runtime.RequiredError('orderRESTEntity','Required parameter requestParameters.orderRESTEntity was null or undefined when calling orderControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderRESTEntityToJSON(requestParameters.orderRESTEntity),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderRESTEntityFromJSON(jsonValue));
    }

    /**
     */
    async orderControllerCreate(requestParameters: OrderControllerCreateRequest): Promise<OrderRESTEntity> {
        const response = await this.orderControllerCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async orderControllerDeleteRaw(requestParameters: OrderControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderControllerDelete.');
        }

        if (requestParameters.permanent === null || requestParameters.permanent === undefined) {
            throw new runtime.RequiredError('permanent','Required parameter requestParameters.permanent was null or undefined when calling orderControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.permanent !== undefined) {
            queryParameters['permanent'] = requestParameters.permanent;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderControllerDelete(requestParameters: OrderControllerDeleteRequest): Promise<void> {
        await this.orderControllerDeleteRaw(requestParameters);
    }

    /**
     */
    async orderControllerFindRaw(requestParameters: OrderControllerFindRequest): Promise<runtime.ApiResponse<OrderRESTEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderControllerFind.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderRESTEntityFromJSON(jsonValue));
    }

    /**
     */
    async orderControllerFind(requestParameters: OrderControllerFindRequest): Promise<OrderRESTEntity> {
        const response = await this.orderControllerFindRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async orderControllerListRaw(requestParameters: OrderControllerListRequest): Promise<runtime.ApiResponse<Array<OrderRESTEntity>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.warehouseId !== undefined) {
            queryParameters['warehouseId'] = requestParameters.warehouseId;
        }

        if (requestParameters.phoneNumber !== undefined) {
            queryParameters['phoneNumber'] = requestParameters.phoneNumber;
        }

        if (requestParameters.expiresAfter !== undefined) {
            queryParameters['expiresAfter'] = requestParameters.expiresAfter;
        }

        if (requestParameters.expiresBefore !== undefined) {
            queryParameters['expiresBefore'] = requestParameters.expiresBefore;
        }

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/orders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderRESTEntityFromJSON));
    }

    /**
     */
    async orderControllerList(requestParameters: OrderControllerListRequest): Promise<Array<OrderRESTEntity>> {
        const response = await this.orderControllerListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async orderControllerUpdateRaw(requestParameters: OrderControllerUpdateRequest): Promise<runtime.ApiResponse<OrderRESTEntity>> {
        if (requestParameters.orderRESTEntity === null || requestParameters.orderRESTEntity === undefined) {
            throw new runtime.RequiredError('orderRESTEntity','Required parameter requestParameters.orderRESTEntity was null or undefined when calling orderControllerUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling orderControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/orders/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrderRESTEntityToJSON(requestParameters.orderRESTEntity),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderRESTEntityFromJSON(jsonValue));
    }

    /**
     */
    async orderControllerUpdate(requestParameters: OrderControllerUpdateRequest): Promise<OrderRESTEntity> {
        const response = await this.orderControllerUpdateRaw(requestParameters);
        return await response.value();
    }

}

import * as React from 'react';

import { Provider } from "react-redux";
import { createStore } from 'redux';
import { ReduxActions, ReduxState, rootReducer } from "../store";
import * as immer from "immer";

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { responsiveFontSizes, ThemeProvider, CssBaseline } from '@material-ui/core';
import etraTheme from "../theme/theme";
import moment from "moment";
import "moment/locale/fi";
import "moment/locale/en-gb";
import strings from "../localization/strings";
import HomeScreen from './screens/home';
import AccessTokenRefresh from "./containers/access-token-refresh";
import StoreInitializer from "./containers/store-initializer";
import OrdersScreen from "./screens/orders";
import SelectWarehouseScreen from "./screens/select-warehouse";
import CreateOrderScreen from "./screens/create-order";
import WareHousesScreen from "./screens/warehouses";
import ModifyWarehouseScreen from "./screens/modify-warehouse";
import CreateWarehouseScreen from "./screens/create-warehouse/create-warehouse-screen";


/**
 * Initialize Redux store
 */
const store = createStore<ReduxState, ReduxActions, any, any>(rootReducer);

/**
 * Material UI automated responsive font sizes
 */
const theme = responsiveFontSizes(etraTheme);

/**
 * Interface describing component properties
 */
interface Props {
}

/**
 * Interface describing component state
 */
interface State {
}

/**
 * App component
 */
class App extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  /**
   * Component did mount life cycle method
   */
  componentDidMount() {
    moment.locale(strings.getLanguage());
    immer.enableAllPlugins();
  }

  render() {
    return (
      <ThemeProvider theme={ theme }>
        <CssBaseline />
        <Provider store={ store }>
          <AccessTokenRefresh>
            <StoreInitializer>
              <BrowserRouter>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={({ history }) => (
                      <HomeScreen history={ history } />
                    )}
                  />
                  <Route
                    exact
                    path="/selectWarehouse"
                    render={({ history }) => (
                      <SelectWarehouseScreen history={ history } />
                    )}
                  />
                  <Route
                    exact
                    path="/orders"
                    render={({ history }) => (
                      <OrdersScreen history={ history } />
                    )}
                  />
                  <Route
                    exact
                    path="/orders/new"
                    render={({ history }) => (
                      <CreateOrderScreen history={ history } />
                    )}
                  />
                  <Route
                    exact
                    path="/warehouses"
                    render={({ history }) => (
                      <WareHousesScreen history={ history } />
                    )}
                  />
                  <Route
                    exact
                    path="/warehouses/new"
                    render={({ history }) => (
                      <CreateWarehouseScreen history={ history } />
                    )}
                  />
                  <Route
                    exact
                    path="/warehouses/:warehouseId"
                    render={({ history, match }) => (
                      <ModifyWarehouseScreen
                        history={ history }
                        warehouseId={ match.params.warehouseId }
                      />
                    )}
                  />
                </Switch>
              </BrowserRouter>
            </StoreInitializer>
          </AccessTokenRefresh>
        </Provider>
      </ThemeProvider>
    );
  }

}

export default App;
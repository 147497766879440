/* tslint:disable */
/* eslint-disable */
/**
 * Etra clearance API
 * Etra clearance API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderRESTEntity
 */
export interface OrderRESTEntity {
    /**
     * 
     * @type {number}
     * @memberof OrderRESTEntity
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderRESTEntity
     */
    warehouseId: number;
    /**
     * 
     * @type {string}
     * @memberof OrderRESTEntity
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRESTEntity
     */
    orderNumber: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRESTEntity
     */
    expires: string;
    /**
     * 
     * @type {string}
     * @memberof OrderRESTEntity
     */
    additionalInformation?: string;
}

export function OrderRESTEntityFromJSON(json: any): OrderRESTEntity {
    return OrderRESTEntityFromJSONTyped(json, false);
}

export function OrderRESTEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderRESTEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'warehouseId': json['warehouseId'],
        'phoneNumber': json['phoneNumber'],
        'orderNumber': json['orderNumber'],
        'expires': json['expires'],
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
    };
}

export function OrderRESTEntityToJSON(value?: OrderRESTEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'warehouseId': value.warehouseId,
        'phoneNumber': value.phoneNumber,
        'orderNumber': value.orderNumber,
        'expires': value.expires,
        'additionalInformation': value.additionalInformation,
    };
}



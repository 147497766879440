/* tslint:disable */
/* eslint-disable */
/**
 * Etra clearance API
 * Etra clearance API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    WarehouseRESTEntity,
    WarehouseRESTEntityFromJSON,
    WarehouseRESTEntityToJSON,
} from '../models';

export interface WarehouseControllerCreateRequest {
    warehouseRESTEntity: WarehouseRESTEntity;
}

export interface WarehouseControllerDeleteRequest {
    id: number;
}

export interface WarehouseControllerFindRequest {
    id: number;
}

export interface WarehouseControllerListRequest {
    shopId?: string;
}

export interface WarehouseControllerUpdateRequest {
    warehouseRESTEntity: WarehouseRESTEntity;
    id: number;
}

/**
 * 
 */
export class WarehousesApi extends runtime.BaseAPI {

    /**
     */
    async warehouseControllerCreateRaw(requestParameters: WarehouseControllerCreateRequest): Promise<runtime.ApiResponse<WarehouseRESTEntity>> {
        if (requestParameters.warehouseRESTEntity === null || requestParameters.warehouseRESTEntity === undefined) {
            throw new runtime.RequiredError('warehouseRESTEntity','Required parameter requestParameters.warehouseRESTEntity was null or undefined when calling warehouseControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/warehouses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WarehouseRESTEntityToJSON(requestParameters.warehouseRESTEntity),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WarehouseRESTEntityFromJSON(jsonValue));
    }

    /**
     */
    async warehouseControllerCreate(requestParameters: WarehouseControllerCreateRequest): Promise<WarehouseRESTEntity> {
        const response = await this.warehouseControllerCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async warehouseControllerDeleteRaw(requestParameters: WarehouseControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling warehouseControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/warehouses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async warehouseControllerDelete(requestParameters: WarehouseControllerDeleteRequest): Promise<void> {
        await this.warehouseControllerDeleteRaw(requestParameters);
    }

    /**
     */
    async warehouseControllerFindRaw(requestParameters: WarehouseControllerFindRequest): Promise<runtime.ApiResponse<WarehouseRESTEntity>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling warehouseControllerFind.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/warehouses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WarehouseRESTEntityFromJSON(jsonValue));
    }

    /**
     */
    async warehouseControllerFind(requestParameters: WarehouseControllerFindRequest): Promise<WarehouseRESTEntity> {
        const response = await this.warehouseControllerFindRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async warehouseControllerListRaw(requestParameters: WarehouseControllerListRequest): Promise<runtime.ApiResponse<Array<WarehouseRESTEntity>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shopId'] = requestParameters.shopId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/warehouses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WarehouseRESTEntityFromJSON));
    }

    /**
     */
    async warehouseControllerList(requestParameters: WarehouseControllerListRequest): Promise<Array<WarehouseRESTEntity>> {
        const response = await this.warehouseControllerListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async warehouseControllerUpdateRaw(requestParameters: WarehouseControllerUpdateRequest): Promise<runtime.ApiResponse<WarehouseRESTEntity>> {
        if (requestParameters.warehouseRESTEntity === null || requestParameters.warehouseRESTEntity === undefined) {
            throw new runtime.RequiredError('warehouseRESTEntity','Required parameter requestParameters.warehouseRESTEntity was null or undefined when calling warehouseControllerUpdate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling warehouseControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/warehouses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WarehouseRESTEntityToJSON(requestParameters.warehouseRESTEntity),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WarehouseRESTEntityFromJSON(jsonValue));
    }

    /**
     */
    async warehouseControllerUpdate(requestParameters: WarehouseControllerUpdateRequest): Promise<WarehouseRESTEntity> {
        const response = await this.warehouseControllerUpdateRaw(requestParameters);
        return await response.value();
    }

}

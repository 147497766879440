import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, OutlinedTextFieldProps, TextField, Typography, WithStyles, withStyles } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import * as React from "react";
import { styles } from "./create-order-screen.styles";
import { History } from "history";
import AppLayout from "../../layouts/app-layout";
import strings from "../../../localization/strings";
import { ReduxActions, ReduxState } from "../../../store";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { OrderRESTEntity, WarehouseRESTEntity } from "../../../generated/client";
import { AccessToken } from "../../../types";
import Api from "../../../api/api";
import * as actions from "../../../actions/warehouses";
import moment from "moment";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  history: History<History.LocationState>;
  warehouses: WarehouseRESTEntity[];
  selectedWarehouse?: WarehouseRESTEntity;
  accessToken?: AccessToken;
  setWarehouses: (warehouses: WarehouseRESTEntity[]) => void;
}

/**
 * Interface describing order DTO
 */
interface OrderDto extends Partial<OrderRESTEntity> {
  validForDays?: number
}

/**
 * Interface describing component state
 */
interface State {
  orderData: OrderDto;
  pristine: boolean;
  loading: boolean;
  failure: boolean;
  success: boolean;
}

/**
 * Component for create order screen
 */
class CreateOrderScreen extends React.Component<Props, State> {

  /**
   * Component constructor
   * 
   * @param props Component constructor
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      pristine: true,
      failure: false,
      success: false,
      loading: false,
      orderData: {
        validForDays: 2
      }
    };
  }

  /**
   * Component did mount life cycle method
   */
  componentDidMount = async () => {
    const { accessToken, warehouses, selectedWarehouse, setWarehouses } = this.props;
    if (!accessToken) {
      return;
    }

    if (warehouses.length < 1) {
      const warehousesApi = Api.getWarehousesApi(accessToken);
      setWarehouses(await warehousesApi.warehouseControllerList({}));
    }

    this.setState({ pristine: true, loading: false });
    if (selectedWarehouse) {
      this.setState({
        orderData: { warehouseId: selectedWarehouse.id, validForDays: 2 }
      });
    }
  }

  /**
   * Component render method
   */
  render() {
    const { classes } = this.props;
    const { loading, success, failure } = this.state;

    return (
      <AppLayout>
        <div className={ classes.container }>
          <Typography className={ classes.primaryHeader } variant="h3">
            { strings.createOrderScreenHeader }
          </Typography>
          { loading ?
            <CircularProgress className={ classes.loader } /> :
            this.renderCreateOrderForm()
          }
          <Dialog
            open={ success }
            onClose={() => this.setState({success: false})}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{strings.createOrderSuccessAlert}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <CheckCircleIcon className={classes.successDialogCheckIcon} />
              <DialogContentText id="alert-dialog-description">
                {strings.createOrderSuccessAlertContent}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({success: false})} color="primary">
                {strings.okButtonText}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={ failure }
            onClose={() => this.setState({failure: false})}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{strings.createOrderFailureAlert}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <ErrorIcon className={classes.errorDialogErrorIcon} />
              <DialogContentText id="alert-dialog-description">
                { strings.createOrderFailureAlertContent }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({failure: false})} color="primary">
                { strings.okButtonText }
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </AppLayout>
    );
  }

  /**
   * Renders order creation form
   */
  private renderCreateOrderForm = () => {
    const { classes, warehouses, history } = this.props;
    const { orderData, pristine } = this.state;
    
    const textFieldProps: OutlinedTextFieldProps = {
      size: "medium",
      fullWidth: true,
      className: classes.formField,
      variant: "outlined",
      InputLabelProps: { variant: "outlined" }
    };

    const warehouseOptions = warehouses.map((warehouse) =>
      <MenuItem key={warehouse.id} value={ warehouse.id }>
        { warehouse.name }
      </MenuItem>
    );

    const orderExpiresOptions = [2, 3, 4, 5].map((days) => 
      <MenuItem key={`valid-for-${days}-days`} value={ days }>
        { strings.formatString(strings.orderValidForDays, days) }
      </MenuItem>
    );

    return (
      <>
        <div className={ classes.formContainer }>
          <TextField
            required={ true }
            label={ strings.createOrderWarehouseLabel }
            error={ !pristine && !orderData.warehouseId }
            helperText={ !pristine && !orderData.warehouseId ? strings.requiredFieldError : "" }
            select
            value={ orderData.warehouseId || ""}
            onChange={ e =>
              this.setState({
                orderData: {
                  ...orderData,
                  warehouseId: e.target.value ? parseInt(e.target.value as string) : undefined
                }
              })
            }
            { ...textFieldProps }
          >
            { warehouseOptions }
          </TextField>
          <TextField
            required={ true }
            label={ strings.orderValidForLabel }
            error={ !pristine && !orderData.validForDays }
            helperText={ !pristine && !orderData.validForDays ? strings.requiredFieldError : "" }
            select
            value={ orderData.validForDays || 2}
            onChange={ e =>
              this.setState({
                orderData: {
                  ...orderData,
                  validForDays: e.target.value ? parseInt(e.target.value) : undefined
                }
              })
            }
            { ...textFieldProps }
          >
            { orderExpiresOptions }
          </TextField>
          <TextField
            required={ true }
            label={ strings.createOrderPhonenumberLabel }
            helperText={ !pristine && !orderData.phoneNumber ? strings.requiredFieldError : "" }
            error={ !pristine && !orderData.phoneNumber }
            value={ orderData.phoneNumber || ""}
            onChange={ e =>
              this.setState({
                orderData: {...orderData, phoneNumber: e.target.value }
              })
            }
            { ...textFieldProps }
          />
          <TextField
            required={ true }
            label={ strings.createOrderOrdernumberLabel }
            helperText={ !pristine && !orderData.orderNumber ? strings.requiredFieldError : "" }
            error={ !pristine && !orderData.orderNumber }
            value={ orderData.orderNumber || ""} 
            onChange={ e =>
              this.setState({
                orderData: {...orderData, orderNumber: e.target.value }
              })
            }
            { ...textFieldProps }
          />
          <TextField
            required={ false }
            multiline
            rows={ 2 }
            label={ strings.orderAdditionalInformationLabel }
            helperText={ `${(orderData.additionalInformation || "").length} / 255` }
            error={ orderData.additionalInformation ? orderData.additionalInformation.length > 255 : false }
            value={ orderData.additionalInformation || ""} 
            onChange={ e =>
              this.setState({
                orderData: {...orderData, additionalInformation: e.target.value }
              })
            }
            { ...textFieldProps }
          />
          <Alert style={{ marginTop: "20px" }} variant="outlined" severity="info">
            <AlertTitle>{ strings.smsAlertTitle }</AlertTitle>
            { this.renderSmsPreview() }
          </Alert>
        </div>
        <div className={ classes.actionButtonContainer }>
          <Button
            className={ classes.actionButton }
            color="primary"
            onClick={ this.handleSendOrderClick }
            variant="contained"
          >
            { strings.createOrderSendButton }
          </Button>
          <Button
            onClick={ () => history.goBack() }
            className={ classes.actionButton }
            variant="outlined"
          >
            { strings.cancelButtonText }
          </Button>
        </div>
      </>
    );
  }

  private renderSmsPreview = () => {
    const { warehouses } = this.props;
    const { orderData } = this.state;
    const selectedWarehouse = orderData.warehouseId ? warehouses.find(w => w.id === orderData.warehouseId) : undefined;
    return (
      <>
        <p>
          {`Etra tilauksesi ${orderData.orderNumber || ""} on noudettavissa 24/7 noutovarastosta koodilla [PIN]`}
          <br/>
          {`Muista syöttää #-merkki pin-koodin perään.`}
          <br/>
          {`Tilaus on noudettava viimeistään ${moment().add(orderData.validForDays, 'd').endOf('d').format("DD.MM.YYYY")}.`}
          <br/>
          {`${orderData.additionalInformation || ""}`}
        </p>
        <p>
          Terveisin,
          <br/>
          { selectedWarehouse ? selectedWarehouse.name : "" }
          <br/>
          Katso yhteystiedot Etran verkkosivuilta
        </p>
      </>
    );
  }

  /**
   * Click handler for create order button
   */
  private handleSendOrderClick = async () => {
    const { orderData } = this.state;
    const { accessToken } = this.props;

    this.setState({ pristine: false });

    if (!accessToken || 
        !orderData.orderNumber || 
        !orderData.phoneNumber ||
        !orderData.validForDays ||
        !orderData.warehouseId ||
        (orderData.additionalInformation || "").length > 255) {
      return;
    }

    this.setState({ loading: true });

    try {
      const order: OrderRESTEntity = {
        orderNumber: orderData.orderNumber,
        phoneNumber: orderData.phoneNumber,
        warehouseId: orderData.warehouseId,
        additionalInformation: orderData.additionalInformation,
        expires: moment().add(orderData.validForDays, 'days').endOf('day').format()
      }
      const api = Api.getOrdersApi(accessToken);
      await api.orderControllerCreate({ orderRESTEntity: order });

      this.setState({
        loading: false,
        success: true,
        pristine: true,
        orderData: {
          validForDays: 2
        }
      });
    } catch(e) {
      this.setState({
        loading: false,
        failure: true
      });
    }
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
const mapStateToProps = (state: ReduxState) => ({
  accessToken: state.auth.accessToken,
  selectedWarehouse: state.warehouses.selectedWarehouse,
  warehouses: state.warehouses.warehouses
});

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
const mapDispatchToProps = (dispatch: Dispatch<ReduxActions>) => ({
  setWarehouses: (warehouses: WarehouseRESTEntity[]) => dispatch(actions.setWarehouses(warehouses))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateOrderScreen));

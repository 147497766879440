import { WarehouseRESTEntity } from "../generated/client";
import * as ActionTypes from '../constants/actionTypes';

/**
 * Interface for set warehouses action type
 */
export interface SetWarehousesAction {
  type: ActionTypes.SET_WAREHOUSES;
  warehouses: WarehouseRESTEntity[];
}

/**
 * Interface for set selected warehouse action type
 */
export interface SetSelectedWarehouseAction {
  type: ActionTypes.SET_SELECTED_WAREHOUSE;
  warehouse: WarehouseRESTEntity;
}

/**
 * Store method for set warehouses
 *
 * @param warehouses warehouses
 */
export function setWarehouses(warehouses: WarehouseRESTEntity[]): SetWarehousesAction {
  return {
    type: ActionTypes.SET_WAREHOUSES,
    warehouses: warehouses
  };
}

/**
 * Store method for set selected warehouse
 *
 * @param warehouse warehouse
 */
export function setSelectedWarehouse(warehouse: WarehouseRESTEntity): SetSelectedWarehouseAction {
  return {
    type: ActionTypes.SET_SELECTED_WAREHOUSE,
    warehouse: warehouse
  };
}

export type WarehousesAction = SetWarehousesAction | SetSelectedWarehouseAction;
import LocalizedStrings, { LocalizedStringsMethods } from "localized-strings";

/**
 * Interface describing localized strings
 */
export interface IStrings extends LocalizedStringsMethods {
  appTitle: string;
  collectPoint: string;
  inputWarehouseId: string;
  warehouseId: string;

  warehouseName: string;
  warehouseShopId: string;
  createWarehouse: string;
  createWarehouseScreenHeader: string;
  modifyWarehouseScreenHeader: string;
  lockId: string;
  updateWarehouse: string;

  noSelectedWarehouse: string;
  selectWarehouseToSeeOrders: string;
  addNewOrder: string;
  orders: string;
  orderTitle: string;
  orderPhoneNumber: string;
  orderPhoneNumberExpired: string;
  warehouses: string;
  selectWarehouse: string;
  logout: string;

  createOrderScreenHeader: string;
  createOrderPhonenumberLabel: string;
  createOrderOrdernumberLabel: string;
  createOrderNameLabel: string;
  createOrderWarehouseLabel: string;
  requiredFieldError: string;
  createOrderSendButton: string;
  createOrderSuccessAlert: string;
  createOrderSuccessAlertContent: string;
  createOrderFailureAlert: string;
  createOrderFailureAlertContent: string;

  currentWarehouseText: string;

  modifyWarehouseSuccessAlert: string;
  modifyWarehouseFailureAlert: string;

  createWarehouseSuccessAlert: string;
  createWarehouseFailureAlert: string;

  deleteButtonText: string;
  modifyButtonText: string;
  cancelButtonText: string;
  okButtonText: string;

  promptDeleteWarehouse: string;
  promptDialogTitle: string;

  orderValidForLabel: string;
  orderValidForDays: string;

  orderAdditionalInformationLabel: string;
  smsAlertTitle: string;

  validOrdersTabTitle: string;
  expiredOrdersTabTitle: string;

  expireButton: string;
  promptExpireOrder: string;
}

const strings: IStrings = new LocalizedStrings({
  en: require("./en.json"),
  fi: require("./fi.json")
});

export default strings;

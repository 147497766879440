/**
 * Login action
 */
export const LOGIN = "LOGIN";
export type LOGIN = typeof LOGIN;

/**
 * Logout action
 */
export const LOGOUT = "LOGOUT";
export type LOGOUT = typeof LOGOUT;

/**
 * Set locale action
 */
export const SET_LOCALE = "SET_LOCALE";
export type SET_LOCALE = typeof SET_LOCALE;

/**
 * Set selected warehouse action
 */
export const SET_SELECTED_WAREHOUSE = "SET_SELECTED_WAREHOUSE";
export type SET_SELECTED_WAREHOUSE = typeof SET_SELECTED_WAREHOUSE;

/**
 * Set warehouses action
 */
export const SET_WAREHOUSES = "SET_WAREHOUSES";
export type SET_WAREHOUSES = typeof SET_WAREHOUSES;

/**
 * Set orders action
 */
export const SET_ORDERS = "SET_ORDERS";
export type SET_ORDERS = typeof SET_ORDERS;